import React from "react";
import styled from "styled-components";
import WeskeyLogo from "../images/project/WeskeyLogo.png";
import GoogleButtonImage from "../images/project/googleBtn.png";
import AppleButtonImage from "../images/project/appleBtn.png";
import { useEffect } from "react";
import { weskeyMetas } from "./PageMetas";

const BWeskey = () => {
  useEffect(() => {
    const favicon = document.querySelector('link[rel="icon"]');
    if (favicon) {
      favicon.href =
        "https://weskey-s3-bucket.s3.ap-northeast-2.amazonaws.com/weskeylogo.png";
    }
  }, []);

  return (
    <div>
      {weskeyMetas()}
      <MainContainer>
        <LogoImage src={WeskeyLogo} alt="위스키 로고" />
        <TextContainer>
          <ShowText>
            모두가 함께 즐기고 소통하는
            <br /> 위스키 세상을 만들어요
          </ShowText>
        </TextContainer>
        <ButtonContainer>
          <DownLoadLink
            href="https://play.google.com/store/search?q=we!skey"
            target="_blank"
          >
            <DownLoadBtn src={GoogleButtonImage} alt="Google Play Store" />
          </DownLoadLink>
          <DownLoadLink
            href="https://apps.apple.com/app/id6478525713"
            target="_blank"
          >
            <DownLoadBtn src={AppleButtonImage} alt="Apple App Store" />
          </DownLoadLink>
        </ButtonContainer>
      </MainContainer>
    </div>
  );
};

const MainContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const LogoImage = styled.img`
  width: 30vw;
  border: 3px solid #000;
  border-radius: 15rem;
`;

const TextContainer = styled.div`
  height: 10vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ShowText = styled.span`
  font-size: 3rem;
  text-align: center;
  font-weight: 600;

  @media (min-width: 1200px) and (max-width: 1700px) {
    font-size: 2rem;
  }
  @media (min-width: 900px) and (max-width: 1199px) {
    font-size: 1.5rem;
  }
  @media (min-width: 300px) and (max-width: 899px) {
    font-size: 1rem;
  }
  @media (min-width: 10px) and (max-width: 299px) {
    font-size: 0.8rem;
  }
`;

const ButtonContainer = styled.div`
  width: 80vw;
  height: 20vh;
  display: flex;
  justify-content: space-between;
  margin-top: 5vh;

  @media (min-width: 1200px) and (max-width: 1700px) {
    margin-top: 4vh;
  }
  @media (min-width: 900px) and (max-width: 1199px) {
    margin-top: 3vh;
  }
  @media (min-width: 300px) and (max-width: 899px) {
    margin-top: 2vh;
  }
  @media (min-width: 10px) and (max-width: 299px) {
    margin-top: 1vh;
  }
`;

const DownLoadBtn = styled.img`
  width: 35vw;
  object-fit: contain;
`;

const DownLoadLink = styled.a`
  width: 35vw;
  object-fit: contain;
`;

export default BWeskey;
