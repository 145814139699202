import styled from "styled-components";
import rocketImage from "../images/header/RocketImage.png";
import circleImage from "../images/header/MainTitleBackground.png";
import logo from "../images/header/Logo.png";
import talentCreation from "../images/vision/TalentCreation.png";
import continuousChallenge from "../images/vision/ContinuousChallenge.png";
import management from "../images/vision/Management.png";
import socialContribution from "../images/vision/SocialContribution.png";
import valueCreation from "../images/vision/ValueCreation.png";
import weskeyLogo from "../images/project/WeskeyLogo.png";
import defaultAppIcon from "../images/project/DefaultAppIcon.png";
import mailIcon from "../images/contact_us/MailIcon.png";
import phoneIcon from "../images/contact_us/PhoneIcon.png";
import addressIcon from "../images/contact_us/AddressIcon.png";
import { Link } from "react-router-dom";
import { appidearealizeMetas } from "./PageMetas";

const visionItems = [
  {
    title: "인재창출",
    text: "다양한 분야의 리더쉽 있는 인재를 창출합니다.",
    icon: talentCreation,
    alt: "Talent Creation",
  },
  {
    title: "지속도전",
    text: "끊임없는 도전을 통해 끊임없이 성장합니다.",
    icon: continuousChallenge,
    alt: "Sustainable ",
  },
  {
    title: "가치창출",
    text: "혁신적인 서비스를 통해 고부가가치 상품을 만들어냅니다.",
    icon: management,
    alt: "Value Creation",
  },
  {
    title: "사회공헌",
    text: "사회의 일원으로서 사회에 도움이 될 수 있게 노력합니다.",
    icon: socialContribution,
    alt: "Social Contribution",
  },
  {
    title: "정도경영",
    text: "회사에서 진행하는 모든일에 있어서 항상 정도를 추구합니다.",
    icon: valueCreation,
    alt: "Governance",
  },
];

const projects = [
  {
    title: "We!skey",
    icon: weskeyLogo,
    alt: "We!skey logo",
    text: "We!skey",
    link: "/weskey",
  },
  {
    title: "We!skey",
    icon: defaultAppIcon,
    alt: "defaultAppIcon",
    text: "App2",
    link: "/",
  },
  {
    title: "We!skey",
    icon: defaultAppIcon,
    alt: "defaultAppIcon",
    text: "App3",
    link: "/",
  },
  {
    title: "We!skey",
    icon: defaultAppIcon,
    alt: "defaultAppIcon",
    text: "App4",
    link: "/",
  },
];

const contactDetails = [
  {
    icon: addressIcon,
    title: "Address",
    description:
      "경기도 성남시 수정구 위례광장로 300 중앙타워 1동 5층 514-117호",
  },
  {
    icon: phoneIcon,
    title: "Phone",
    description: "070.4513.1815",
  },
  {
    icon: mailIcon,
    title: "Mail",
    description: "contact@appidearealize.com",
  },
];

const BMain = () => {
  return (
    <div>
      {appidearealizeMetas()}
      <BMainContainer>
        <NavigationBarContainer>
          <LogoContainer>
            <LogoIcon alt="logo" src={logo} />
          </LogoContainer>
          <NavigationTextContainer>
            <NavigationText>Contact</NavigationText>
            <NavigationText>Project</NavigationText>
            <NavigationText>Vision</NavigationText>
            <NavigationText>Company</NavigationText>
          </NavigationTextContainer>
        </NavigationBarContainer>

        <HeaderContainer>
          <HeaderTitleContainer>
            <Title>
              <Titleblue>
                아이디어
                <TitleBlack>
                  를 앱/웹으로 <br />
                  실현시킵니다.
                </TitleBlack>
              </Titleblue>
            </Title>
            <SubTitle>
              <SubTitleText>
                무궁무진한 아이디어들을 가지고 앱/웹을 개발함으로써 <br />
                많은 사람들의 니즈를 충족시키고 불편함을 해소, <br />
                즐거움을 주는 것을 추구합니다.
              </SubTitleText>
            </SubTitle>
          </HeaderTitleContainer>
          <RocketImage alt="rocketImage" src={rocketImage} />
          <CircleImage alt="circleImage" src={circleImage} />
        </HeaderContainer>

        <VisionContainer>
          <VisionItemsContainer>
            <VisionTitleContainer>
              <VisionTitle>VISION</VisionTitle>
              <VisionSubtitle>
                끊임없는 도전으로 한발 더 나아가는 air가 되겠습니다.
              </VisionSubtitle>
            </VisionTitleContainer>
            {visionItems.map((item, index) => (
              <VisionItem key={index}>
                <VisionItemTitle>{item.title}</VisionItemTitle>
                <VisionItemText>{item.text}</VisionItemText>
                <VisionItemIcon src={item.icon} alt={item.alt} />
              </VisionItem>
            ))}
          </VisionItemsContainer>
        </VisionContainer>

        <ProjectContainer>
          <ProjectTitleContainer>
            <ProjectTitle>Project</ProjectTitle>
            <ProjectSubtitle>
              성공적으로 수행해온 프로젝트입니다.
            </ProjectSubtitle>
          </ProjectTitleContainer>
          <ProjectItemsContainer>
            {projects.map((item, index) => (
              <ProjectItem key={index}>
                <ProjectItemLink to={item.link}>
                  <ProjectItemIcon src={item.icon} alt={item.alt} />
                </ProjectItemLink>
                <ProjectItemText>{item.text}</ProjectItemText>
              </ProjectItem>
            ))}
          </ProjectItemsContainer>
        </ProjectContainer>

        <ContactContainer>
          <ContactInfoContainer>
            <ContactTitleContainer>
              <ContactTitle>Contact us</ContactTitle>
              <ContactSubtitle>
                A.I.R에 문의주시면 신속하게 답변드리겠습니다.
              </ContactSubtitle>
            </ContactTitleContainer>
            {contactDetails.map((detail, index) => (
              <ContactDetailContainer key={index}>
                <ContactIcon src={detail.icon}></ContactIcon>
                <ContactDetailTitle>{detail.title}</ContactDetailTitle>
                <ContactDetailDescription>
                  {detail.description}
                </ContactDetailDescription>
              </ContactDetailContainer>
            ))}
          </ContactInfoContainer>
          <MapContainer>
            <GoogleMaps
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4183.7699728926455!2d127.14071382333222!3d37.475031214767725!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357caf68b745ae27%3A0x9e04b1de3640659f!2z7JyE66GA7KSR7JWZ7YOA7JuM!5e0!3m2!1sko!2skr!4v1720075167260!5m2!1sko!2skr"
              loading="lazy"
            ></GoogleMaps>
          </MapContainer>
        </ContactContainer>

        <FooterContainer>
          <FooterText>
            <Address>
              경기도 성남시 수정구 위례광장로 300 중앙타워 1동 5층 514-117호
            </Address>
            <TelephoneNumber>| Tel: 070.4513.1815</TelephoneNumber>
            <Email>| E-mail: contact@appidearealize.com</Email>
            <br />
            COPYRIGHT © 2024 AIR CO., ALL RIGHTS RESERVED
          </FooterText>
        </FooterContainer>
      </BMainContainer>
    </div>
  );
};

const BMainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const NavigationBarContainer = styled.div`
  width: 70%;
  height: 10vh;
  display: flex;
  justify-content: space-between;
`;

const LogoContainer = styled.div`
  width: 10vw;
  height: 100%;
  display: flex;
  align-items: center;
`;

const LogoIcon = styled.img`
  height: 60%;
  object-fit: contain;

  @media (min-width: 1px) and (max-width: 800px) {
    height: 30%;
  }
`;

const NavigationTextContainer = styled.div`
  width: 25%;
  max-width: 400px; /* 최대 너비를 200px로 설정 */
  display: flex;
  font-size: 1rem;
  text-transform: uppercase;
  align-items: center;
  justify-content: space-between;
  font-weight: 600;
  line-height: 1.3%;
  gap: 10%;

  @media (min-width: 1px) and (max-width: 1300px) {
    display: none;
  }
`;

const NavigationText = styled.div`
  text-align: center;
`;

const HeaderContainer = styled.div`
  width: 70%;
  height: 45vh;
  margin: 3% 0% 5% 0%;

  @media (min-width: 400px) and (max-width: 800px) {
    height: 35vh;
  }
  @media (min-width: 1px) and (max-width: 399px) {
    height: 60vh;
  }
`;

const HeaderTitleContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const Title = styled.b`
  z-index: 9;
  display: flex;
  font-size: 5rem;

  @media (min-width: 800px) and (max-width: 1200px) {
    font-size: 4rem;
  }
  @media (min-width: 400px) and (max-width: 799px) {
    font-size: 2rem;
  }
  @media (min-width: 1px) and (max-width: 399px) {
    font-size: 1.6rem;
  }
`;

const Titleblue = styled.span`
  color: var(--color-cornflowerblue-100);
`;

const TitleBlack = styled.span`
  color: #000;
`;

const SubTitle = styled.div`
  font-size: 1.3rem;
  z-index: 2; /* 텍스트를 이미지보다 위에 오게 설정 */

  @media (min-width: 800px) and (max-width: 1200px) {
    font-size: 1rem;
  }
  @media (min-width: 400px) and (max-width: 799px) {
    font-size: 1rem;
  }
  @media (min-width: 1px) and (max-width: 399px) {
    padding-right: 1rem;
    font-size: 1rem;
  }
`;

const SubTitleText = styled.p`
  line-height: 2;
`;

const RocketImage = styled.img`
  position: absolute;
  top: 0;
  right: 20vw;
  width: 35%;
  height: 70%;
  object-fit: contain;
  z-index: 1; /* 텍스트보다 아래에 오게 설정 */

  @media (min-width: 1px) and (max-width: 800px) {
    width: 60%;
  }
`;

const CircleImage = styled.img`
  position: absolute;
  top: 5%;
  left: 15%;
  width: 6%;
  height: 30%;
  object-fit: contain;

  @media (min-width: 800px) and (max-width: 1200px) {
    width: 15%;
    top: 5%;
  }
  @media (min-width: 1px) and (max-width: 799px) {
    width: 20%;
    top: 3%;
  }
`;

// vision style

const VisionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 70vh;
  background-color: var(--color-cornflowerblue-200);
  grid-template-columns: repeat(3, 1fr);
  color: white;

  @media (min-width: 1300px) and (max-width: 1700px) {
    height: 80vh;
  }

  @media (min-width: 800px) and (max-width: 1299px) {
    height: 100vh;
    padding: 1rem;
  }
  @media (min-width: 600px) and (max-width: 799px) {
    height: 70vh;
    padding: 1rem;
  }
  @media (min-width: 450px) and (max-width: 599px) {
    height: 170vh;
  }
  @media (min-width: 1px) and (max-width: 449px) {
    height: 150vh;
  }
`;

const VisionTitleContainer = styled.div`
  text-align: left;
  margin-bottom: 2%;

  @media (min-width: 800px) and (max-width: 1300px) {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
`;

const VisionTitle = styled.h1`
  font-size: 3.5em;
  margin: 0;

  @media (min-width: 800px) and (max-width: 1300px) {
    font-size: 2rem;
  }
  @media (min-width: 400px) and (max-width: 799px) {
    font-size: 2rem;
  }
  @media (min-width: 1px) and (max-width: 399px) {
    font-size: 1.6rem;
  }
`;

const VisionSubtitle = styled.p`
  font-size: 1.5em;
  font-weight: 400;
  margin: 0.7em 0 0 0;
  width: 180px; // 텍스트가 3줄로 나오도록 너비 조정

  @media (min-width: 400px) and (max-width: 799px) {
    font-size: 1.2rem;
  }
  @media (min-width: 1px) and (max-width: 399px) {
    font-size: 1rem;
  }
`;

const VisionItemsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  width: 70%;

  @media (min-width: 600px) and (max-width: 1300px) {
    grid-template-columns: repeat(2, 1fr);
    width: 80%;
  }
  @media (min-width: 1px) and (max-width: 599px) {
    grid-template-columns: repeat(1, 1fr);
    width: 70%;
  }
`;

const VisionItem = styled.div`
  background-color: ${(props) => props.bgColor || "#f0fcff"};
  border-radius: 10px;
  padding-top: 5%;
  padding-bottom: 5%;
  padding-left: 10%;
  padding-right: 10%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const VisionItemTitle = styled.h2`
  font-size: 1.5em;
  color: #0066cc;
  margin: 5% 0px 0px 5%;
`;

const VisionItemText = styled.p`
  font-size: 1em;
  font-weight: 500;
  margin: 5% 0px 0px 5%;
  color: #333;
  width: 200px;
`;

const VisionItemIcon = styled.img`
  width: 20%;
  height: 30%;
  max-width: 70px;
  max-height: 70px;
  margin: 1% 0px 1% 80%;
`;

// project style

const ProjectContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 70vh;
  background-color: #f8f8f8;
  color: black;

  @media (min-width: 1200px) and (max-width: 1700px) {
    height: 70vh;
  }
  @media (min-width: 900px) and (max-width: 1199px) {
    height: 50vh;
  }
  @media (min-width: 600px) and (max-width: 899px) {
    height: 40vh;
  }
  @media (min-width: 450px) and (max-width: 599px) {
    height: 80vh;
  }
  @media (min-width: 100px) and (max-width: 449px) {
    height: 90vh;
  }
`;

const ProjectTitleContainer = styled.div`
  text-align: left;
  width: 70%;
  margin-bottom: 4%;
`;

const ProjectTitle = styled.h1`
  font-size: 3.5em;
  color: #333;
  @media (min-width: 1200px) and (max-width: 1700px) {
    font-size: 3rem;
  }
  @media (min-width: 900px) and (max-width: 1199px) {
    font-size: 2.5rem;
  }
  @media (min-width: 500px) and (max-width: 899px) {
    font-size: 2rem;
  }
  @media (min-width: 1px) and (max-width: 499px) {
    font-size: 1.5rem;
  }
`;

const ProjectSubtitle = styled.p`
  font-size: 1.5em;
  margin: 0.1em 0 0 0;
  color: #666;
  @media (min-width: 1200px) and (max-width: 1700px) {
    font-size: 1.3rem;
  }
  @media (min-width: 900px) and (max-width: 1199px) {
    font-size: 1.2rem;
  }
  @media (min-width: 1px) and (max-width: 899px) {
    font-size: 1rem;
  }
`;

const ProjectItemsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  width: 60%;
  gap: 5%;

  @media (min-width: 1px) and (max-width: 600px) {
    padding-top: 10%;
    grid-template-columns: repeat(2, 1fr);
  }
`;

const ProjectItem = styled.div`
  border-radius: px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ProjectItemIcon = styled.img`
  width: 90%;
  height: 90%;
  border-radius: 50%;
  margin-bottom: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* 명암 효과 추가 */
`;

const ProjectItemLink = styled(Link)`
  width: 90%;
  height: 90%;
  border-radius: 50%;
  margin-bottom: 10px;
  cursor: pointer;
`;

const ProjectItemText = styled.p`
  font-size: 1em;
  font-weight: 600;
  color: #333;
`;

// contact us

const ContactContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 70vh;
  background-color: white;

  @media (min-width: 1600px) and (max-width: 1900px) {
    height: 60vh;
  }
  @media (min-width: 1100px) and (max-width: 1599px) {
    height: 80vh;
  }
  @media (min-width: 800px) and (max-width: 1099px) {
    height: 45vh;
  }
  @media (min-width: 500px) and (max-width: 799px) {
    height: 60vh;
  }
  @media (min-width: 200px) and (max-width: 499px) {
    height: 70vh;
  }
`;

const ContactTitleContainer = styled.div`
  background-color: white;
`;

const ContactInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin-left: 15%;
  @media (min-width: 1400px) and (max-width: 1900px) {
    margin-left: 10%;
    padding-right: 10%;
  }
  @media (min-width: 900px) and (max-width: 1399px) {
    margin-left: 20%;
  }
  @media (min-width: 500px) and (max-width: 899px) {
    margin-left: 20%;
    padding-right: 20%;
  }
  @media (min-width: 1px) and (max-width: 499px) {
    margin-left: 20%;
    padding-right: 20%;
  }
`;

const ContactTitle = styled.h1`
  font-size: 3.5em;
  margin: 0;
  color: #333;
  @media (min-width: 1200px) and (max-width: 1700px) {
    font-size: 3rem;
  }
  @media (min-width: 900px) and (max-width: 1199px) {
    font-size: 2.5rem;
  }
  @media (min-width: 500px) and (max-width: 899px) {
    font-size: 2rem;
  }
  @media (min-width: 1px) and (max-width: 499px) {
    font-size: 1.5rem;
  }
`;

const ContactSubtitle = styled.p`
  font-size: 1.5em;
  margin: 1em 0 2em 0;
  font-weight: 400;
  @media (min-width: 1200px) and (max-width: 1700px) {
    font-size: 1.3rem;
  }
  @media (min-width: 900px) and (max-width: 1199px) {
    font-size: 1.2rem;
  }
  @media (min-width: 1px) and (max-width: 899px) {
    font-size: 1rem;
  }
`;

const ContactDetailContainer = styled.div`
  margin: 0.5em 0;
  color: #333;
  display: flex;
  align-items: center;
  width: 100%;
  padding-right: 10%;

  @media (min-width: 900px) and (max-width: 1199px) {
    font-size: 1.2rem;
  }
  @media (min-width: 600px) and (max-width: 899px) {
    font-size: 1rem;
  }
  @media (min-width: 200px) and (max-width: 599px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const ContactDetailTitle = styled.span`
  font-size: 0.8em;
  margin: 0.5em 0;
  color: #333;
  width: 8%;
  padding-right: 10%;
`;

const ContactDetailDescription = styled.span`
  width: 100%;
  font-size: 0.9em;
  font-weight: 600;
  color: #333;
`;

const ContactIcon = styled.img`
  margin-right: 10px;
  width: 4%;
  height: 4%;
  @media (min-width: 1px) and (max-width: 1199px) {
    display: none;
  }
`;

const MapContainer = styled.div`
  width: 100%;
  height: 100%;

  @media (min-width: 1px) and (max-width: 1199px) {
    display: none;
  }
`;

//footer

const FooterContainer = styled.div`
  background-color: #1c1c1c; /* 배경 색상 */
  padding: 1% 0% 1% 0%;
  color: #ccc; /* 글자 색상 */
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const FooterText = styled.div`
  text-align: center;
  width: 100%;
  font-size: 0.8em; /* 글자 크기 */
  font-weight: 300;
  line-height: 1.8; /* 줄 간격 */
`;

const Address = styled.span`
  font-size: 0.9em; /* 글자 크기 */
  font-weight: 300;
`;

const TelephoneNumber = styled.span`
  font-size: 0.9em; /* 글자 크기 */
  font-weight: 300;
  margin-left: 1%;
`;

const Email = styled.span`
  font-size: 0.9em; /* 글자 크기 */
  font-weight: 300;
  margin-left: 1%;
`;

const GoogleMaps = styled.iframe`
  width: 50vw;
  height: 100%;
  border: 0px;

  @media (min-width: 1px) and (max-width: 1199px) {
    display: none;
  }
`;

export default BMain;
