import { Helmet } from "react-helmet-async";

export function appidearealizeMetas() {
  return (
    <Helmet>
      <title>A.I.R</title>
      <link
        rel="icon"
        href="https://weskey-s3-bucket.s3.ap-northeast-2.amazonaws.com/air_logo.png"
      />
      <meta property="og:title" content="A.I.R 홈페이지" />
      <meta property="og:type" content="website" />
      <meta
        property="og:image"
        content="https://weskey-s3-bucket.s3.ap-northeast-2.amazonaws.com/air_logo.png"
      />
      <meta property="og:url" content="https://appidearealize.com" />
      <meta property="og:description" content="A.I.R 홈페이지 입니다." />
    </Helmet>
  );
}

export function weskeyMetas() {
  return (
    <Helmet>
      <title>We!skey 다운로드 페이지</title>
      <link
        rel="icon"
        href="https://weskey-s3-bucket.s3.ap-northeast-2.amazonaws.com/weskeylogo.png"
      />
      <link
        rel="icon"
        type="image/png"
        content="https://weskey-s3-bucket.s3.ap-northeast-2.amazonaws.com/weskeylogo.png"
      />
      <meta property="og:title" content="We!skey" />
      <meta property="og:type" content="website" />
      <meta
        property="og:image"
        content="https://weskey-s3-bucket.s3.ap-northeast-2.amazonaws.com/weskeylogo.png"
      />
      <meta property="og:url" content="https://appidearealize.com/weskey" />
      <meta
        property="og:description"
        content="We!skey 다운로드 페이지 입니다."
      />
    </Helmet>
  );
}
