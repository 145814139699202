import { Routes, Route, Router } from "react-router-dom";
import BMain from "./pages/BMain";
import { BrowserRouter } from "react-router-dom";
import BWeskey from "./pages/BWeskey";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<BMain />} />
        <Route path="/weskey" element={<BWeskey />} />
      </Routes>
    </BrowserRouter>
  );
}
export default App;
